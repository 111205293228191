import * as React from "react"
import Layout from 'components/layout';
import PageBanner from "components/page_banner";
import {getCloudFrontImageUrl} from "utils/helpers";

import Button from "components/button";
import "./404.scss";
import topCorner from "../images/icons/404-top.svg";
import bottomCorner from "../images/icons/404-bottom.svg";

// styles
// const pageStyles = {
//   color: "#232129",
//   padding: "96px",
//   fontFamily: "-apple-system, Roboto, sans-serif, serif",
// }
// const headingStyles = {
//   marginTop: 0,
//   marginBottom: 64,
//   maxWidth: 320,
// }

// const paragraphStyles = {
//   marginBottom: 48,
// }
// const codeStyles = {
//   color: "#8A6534",
//   padding: 4,
//   backgroundColor: "#FFF4DB",
//   fontSize: "1.25rem",
//   borderRadius: 4,
// }

// markup
const NotFoundPage = () => {
  return (
    <Layout pageTitle={"404 Page"} script={<>
      <script>
        {"gtag('event', 'conversion', {'allow_custom_scripts': true,'send_to': 'DC-12429278/visit/homep0+unique'});"}
      </script>
      <noscript>
        <img src="https://ad.doubleclick.net/ddm/activity/src=12429278;type=visit;cat=homep0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1;num=1?" width="1" height="1" alt=""/>
      </noscript>
    </>}>
      <PageBanner
        headerBackgroundPath={getCloudFrontImageUrl("advertisers/404.jpg")}
        verticalStrip
      />
      <div className="not-found-container uk-flex uk-flex-center uk-flex-column uk-flex-middle uk-width-auto uk-padding-large"
        >
        <div className="not-found-text-container uk-flex uk-flex-column uk-flex-middle uk-text-center uk-width-2xlarge uk-padding-large">
          <img className="not-found-top-icon" src={topCorner} alt=""/>
          <span className="not-found-header">404</span>
          <span className="not-found-sub-text">Oops, there's nothing here</span>
          <img className="not-found-bottom-icon" src={bottomCorner}  alt=""/>
        </div>
        <div className="not-found-buttons-container uk-grid uk-flex-middle uk-flex-center uk-padding-small">
          <div className="not-found-button-container">
            <Button to={"/resources"}>Resources</Button>
          </div>
          <div className="not-found-button-container">
            <Button to={"/contact-us"}>Contact Us</Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
